<template>
    <section class="detail-dashboard">
        <div class="container-fluid text-white text-left">
            <div class="mx-3 inner-detail">
                <h5 class="font-weight-normal text-none mb-1">Contents</h5>
                <div class="d-flex align-items-center justify-content-between">
                    <div class="d-flex align-items-center justify-content-start">
                        <h2>{{ total ? total : 0 }}</h2>
                        <img src="@/assets/images/icons/ic_dashboard_arrow.svg" alt="" class="ml-1">
                    </div>
                    <div class="d-flex align-items-center justify-content-end sorting-by">
                        <button class="btn btn-outline-purple mr-1"><p class="mb-0 mx-1">Export to excel</p></button>
                        <div class="ml-2">
                            <select @change="filterByDays" v-model="option_date" id="inputState" class="form-control">
                                <option value="7" selected>Last 7 days</option>
                                <option value="90">Last 90 days</option>
                                <option value="365">Last 365 days</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="loading == true" style="height:215px" class="d-flex justify-content-center align-items-center mb-3">
                <b-spinner label="Loading..."></b-spinner>
            </div>
            <template v-if="loading == false">
                <apexchart type="area" height="350" :options="chartOptions" :series="series"></apexchart>
            </template>
        </div>
    </section>
</template>

<script>
import $ from "jquery";
import { getDashboard } from '@/services/dashboard/dashboard.service'


export default {
    data() {
        return {
            option_date: 7,
            loading: false,
            total: null,
            series: [
                {
                    name: '',
                    data : [
                        [ '2022-01-11', 1 ], 
                        [ '2022-01-12', 20 ], 
                        [ '2022-01-13', 10 ] , 
                        [ '2022-01-14', 40 ], 
                        [ '2022-01-15', 20 ]
                    ],
                    
                },
            ],
       
            chartOptions: {
                chart: {
                    height: 200,
                    type: 'area',
                    toolbar: {
                        show: false
                    },
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    curve: 'straight'
                },
                grid: {
                    show: true,
                    borderColor: '#585859',
                },
                xaxis: {
                    type: 'datetime',
                    labels: {
                        style: {
                            colors : '#fff'
                        }
                    }   
                },
                yaxis: {
                    labels: {
                        style: {
                            colors : [ '#fff' ]
                        }
                    }
                },
                tooltip: {
                    theme : 'dark',
                    x: {
                            format: 'dd MMM yyyy'
                        },
                    },
            },
        }
    },

    mounted(){
        $("section.detail-dashboard").parent().addClass("m-0");
        this.setSideBar()

        var data = {
            option_date: this.option_date,
        }

        this.getDashboard(data)
    },

    methods:{   
        setSideBar() {
            this.$store.commit("IS_SIDEBAR_ACTIVE", false);
        },

        filterByDays(e){
            var data = {
                option_date: e.target.value,
            }
            this.getDashboard(data)
        },

        getDashboard(data){
            this.loading = true;
            this.series[0].data = []
                    
            getDashboard(data)
            .then((response) => {
                if(response.status == true){
                    if(this.$route.query.key == 'contents'){
                        this.series[0].data = response.data.content_chart.content_data.reverse()
                        this.total = response.data.content_chart.total_content
                    } else if(this.$route.query.key == 'plays') {
                        this.series[0].data = response.data.play_chart.play_data.reverse()
                        this.total = response.data.play_chart.total_play
                    }

                    this.loading = false;
                }
            })
        },
    }
}
</script>

<style scoped>
.text-none{
    color: #8C8C8C
}

.inner-detail img {
    width: 16px;
    height: 16px;
}

.sorting-by .form-control{
    border-radius: 20px;
    background-color: #222222;
    border: 0;
    color: white;
}

.btn-outline-purple{
    color: white;
    border: 2px solid #6D75F6;
    border-radius: 20px;
    background: transparent;
}
</style>