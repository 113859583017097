import { render, staticRenderFns } from "./DetailDashboard.vue?vue&type=template&id=5aed1a90&scoped=true&"
import script from "./DetailDashboard.vue?vue&type=script&lang=js&"
export * from "./DetailDashboard.vue?vue&type=script&lang=js&"
import style0 from "./DetailDashboard.vue?vue&type=style&index=0&id=5aed1a90&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5aed1a90",
  null
  
)

export default component.exports